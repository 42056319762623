import { lazy, startTransition } from "react";

const Tenant = lazy(() => {
  let TenantComponent;
  startTransition(() => {
    TenantComponent = import("./views/tenants/Index");
  });
  return TenantComponent;
});

const CreateTenant = lazy(() => {
  let CreateTenantComponent;
  startTransition(() => {
    CreateTenantComponent = import("./views/tenants/Create");
  });
  return CreateTenantComponent;
});

const EditTenant = lazy(() => {
  let EditTenantComponent;
  startTransition(() => {
    EditTenantComponent = import("./views/tenants/Edit");
  });
  return EditTenantComponent;
});

const Forms = lazy(() => {
  let FormsComponent;
  startTransition(() => {
    FormsComponent = import("./views/forms/Index");
  });
  return FormsComponent;
});

const CreateForm = lazy(() => {
  let CreateFormComponent;
  startTransition(() => {
    CreateFormComponent = import("./views/forms/Create");
  });
  return CreateFormComponent;
});

const EditForm = lazy(() => {
  let EditFormComponent;
  startTransition(() => {
    EditFormComponent = import("./views/forms/Edit");
  });
  return EditFormComponent;
});

const CloneForm = lazy(() => {
  let CloneFormComponent;
  startTransition(() => {
    CloneFormComponent = import("./views/forms/Clone");
  });
  return CloneFormComponent;
});

const FormSubmissions = lazy(() => {
  let FormSubmissionsComponent;
  startTransition(() => {
    FormSubmissionsComponent = import("./views/forms/Submissions");
  });
  return FormSubmissionsComponent;
});

const Users = lazy(() => {
  let UsersComponent;
  startTransition(() => {
    UsersComponent = import("./views/users/Index");
  });
  return UsersComponent;
});

const CreateUser = lazy(() => {
  let CreateUserComponent;
  startTransition(() => {
    CreateUserComponent = import("./views/users/Create");
  });
  return CreateUserComponent;
});

const EditUser = lazy(() => {
  let EditUserComponent;
  startTransition(() => {
    EditUserComponent = import("./views/users/Edit");
  });
  return EditUserComponent;
});

const Profile = lazy(() => {
  let ProfileComponent;
  startTransition(() => {
    ProfileComponent = import("./views/auth/Profile");
  });
  return ProfileComponent;
});

const FormVisualization = lazy(() => {
  let FormVisualizationComponent;
  startTransition(() => {
    FormVisualizationComponent = import("./views/forms/Visualization");
  });
  return FormVisualizationComponent;
});

const FormTypes = lazy(() => {
  let FormTypesComponent;
  startTransition(() => {
    FormTypesComponent = import("./views/formTypes/Index");
  });
  return FormTypesComponent;
});

const routes = [
  // { path: '/render-form', name: 'Render Form', element: RenderForm, exact: true },
  // { path: '/create-form', name: 'Create Form', element: CreateFormIframe, exact: true },
  // { path: '/FormSubmissionsOverviews', name: 'Get Submissions', element: GetSubmissions, exact: true },
  // { path: '/edit-form', name: 'Edit Form', element: EditFormIframe, exact: true },

  { path: "/", exact: true },

  { path: "/tenants", name: "Tenants", element: Tenant, exact: true },
  {
    path: "/tenants/create",
    name: "Create Tenant",
    element: CreateTenant,
    exact: true,
  },
  {
    path: "/tenants/edit/:id",
    name: "Edit Tenant",
    element: EditTenant,
    exact: true,
  },

  { path: "/forms", name: "Forms", element: Forms, exact: true },
  {
    path: "/forms/create",
    name: "Create Form",
    element: CreateForm,
    exact: true,
  },
  {
    path: "/forms/edit/:form_id",
    name: "Edit Form",
    element: EditForm,
    exact: true,
  },
  {
    path: "/forms/clone/:form_id",
    name: "Clone Form",
    element: CloneForm,
    exact: true,
  },
  {
    path: "/forms/submissions/:form_id",
    name: "Form Submissions",
    element: FormSubmissions,
    exact: true,
  },
  {
    path: "/forms/visualization/:form_id",
    name: "Form Visualization",
    element: FormVisualization,
    exact: true,
  },

  { path: "/users", name: "Users", element: Users, exact: true },
  {
    path: "/users/create",
    name: "Create User",
    element: CreateUser,
    exact: true,
  },
  {
    path: "/users/edit/:user_id",
    name: "Edit User",
    element: EditUser,
    exact: true,
  },

  { path: "/form-types", name: "Form Types", element: FormTypes, exact: true },


  { path: "/auth/profile", name: "Profile", element: Profile, exact: true },
];

export default routes;
