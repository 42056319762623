const API_URL = process.env.REACT_APP_API_URL;
const WEB_URL = process.env.REACT_APP_WEB_URL;

export const BasePortalUrl = () => {
  const host = window.location.hostname;

  return WEB_URL;
}
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const host = window.location.hostname;
console.log(API_URL)
  return API_URL;
};
