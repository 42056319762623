import {
  CNavItem,
  CSidebar,
  CSidebarBrand,
  CSidebarHeader,
  CSidebarNav,
} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import jtcLogo from "../../assets/brand/jtc-logo.png";
import defaultLogo from "../../assets/brand/logo-w.png";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faHouseUser,
  faRectangleList,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AppSidebar = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarState.sidebarShow);
  const location = useLocation();
  return (
    <>
      <CSidebar
        className="border-end shadow-sm"
        colorScheme={"dark"}
        position="fixed"
        visible={sidebarShow}
        onVisibleChange={(visible) => {
          dispatch({ type: "set", sidebarShow: visible });
        }}
      >
        <CSidebarHeader className="justify-content-evenly p-0">
          <CSidebarBrand className={"m-auto text-center py-3"}>
            <img src={process.env.REACT_APP_ENV === 'jtc' ? jtcLogo : defaultLogo} alt="logo" className="w-75" />
          </CSidebarBrand>
        </CSidebarHeader>
        <CSidebarNav>
          <CNavItem href="/" active={location.pathname === "/"}>
            <FontAwesomeIcon className="nav-icon" icon={faChartPie} />
            {t("Dashboard")}
          </CNavItem>
          <CNavItem href="/form-types" active={location.pathname.includes("/form-types")}>
            <FontAwesomeIcon className="nav-icon" icon={faRectangleList} />
            {t("Form Types")}
          </CNavItem>
          <CNavItem
            href="/tenants"
            active={location.pathname.includes("/tenants")}
          >
            <FontAwesomeIcon className="nav-icon" icon={faHouseUser} />
            {t("Tenants")}
          </CNavItem>
          <CNavItem href="/users" active={location.pathname.includes("/users")}>
            <FontAwesomeIcon className="nav-icon" icon={faUsers} />
            {t("Users")}
          </CNavItem>
          <CNavItem href="/forms" active={location.pathname.includes("/forms")}>
            <FontAwesomeIcon className="nav-icon" icon={faRectangleList} />
            {t("Forms")}
          </CNavItem>

          {/*<CNavGroup toggler={<> <FontAwesomeIcon className='nav-icon' icon={faRectangleList} /> Nav dropdown</>}>*/}
          {/*  <CNavItem href="#"><span className="nav-icon"><span className="nav-icon-bullet"></span></span> Nav dropdown item</CNavItem>*/}
          {/*  <CNavItem href="#"><span className="nav-icon"><span className="nav-icon-bullet"></span></span> Nav dropdown item</CNavItem>*/}
          {/*</CNavGroup>*/}
        </CSidebarNav>
      </CSidebar>
    </>
  );
};

export default AppSidebar;
