import React, { lazy, Suspense, startTransition } from "react";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "./views/pageNotFound";
import UseLanguageDetectorHook from "./hooks/UseLanguageDetectorHook";
import ProtectedRoute from "./components/ProtectedRoute";
import "./scss/style.scss";

function NoMatch() {
  return <PageNotFound />;
}

const RenderForm = lazy(() => {
  let RenderFormComponent;
  startTransition(() => {
    RenderFormComponent = import("./components/RenderForm");
  });
  return RenderFormComponent;
});

const CreateFormIframe = lazy(() => {
  let CreateFormIframeComponent;
  startTransition(() => {
    CreateFormIframeComponent = import("./components/CreateForm");
  });
  return CreateFormIframeComponent;
});

const GetSubmissions = lazy(() => {
  let GetSubmissionsComponent;
  startTransition(() => {
    GetSubmissionsComponent = import("./components/GetSubmissions");
  });
  return GetSubmissionsComponent;
});

const EditFormIframe = lazy(() => {
  let EditFormIframeComponent;
  startTransition(() => {
    EditFormIframeComponent = import("./components/EditForm");
  });
  return EditFormIframeComponent;
});

const Dashboard = lazy(() => {
  let DashboardComponent;
  startTransition(() => {
    DashboardComponent = import("./views/dashboard");
  });
  return DashboardComponent;
});

const Login = lazy(() => {
  let LoginComponent;
  startTransition(() => {
    LoginComponent = import("./views/auth/Login");
  });
  return LoginComponent;
});

const ForgetPassword = lazy(() => {
  let ForgetPasswordComponent;
  startTransition(() => {
    ForgetPasswordComponent = import("./views/auth/ForgetPassword");
  });
  return ForgetPasswordComponent;
});

const ResetPassword = lazy(() => {
  let ResetPasswordComponent;
  startTransition(() => {
    ResetPasswordComponent = import("./views/auth/ResetPassword");
  });
  return ResetPasswordComponent;
});

const Tenant = lazy(() => {
  let TenantComponent;
  startTransition(() => {
    TenantComponent = import("./views/tenants/Index");
  });
  return TenantComponent;
});

const CreateTenant = lazy(() => {
  let CreateTenantComponent;
  startTransition(() => {
    CreateTenantComponent = import("./views/tenants/Create");
  });
  return CreateTenantComponent;
});

const EditTenant = lazy(() => {
  let EditTenantComponent;
  startTransition(() => {
    EditTenantComponent = import("./views/tenants/Edit");
  });
  return EditTenantComponent;
});

const Forms = lazy(() => {
  let FormsComponent;
  startTransition(() => {
    FormsComponent = import("./views/forms/Index");
  });
  return FormsComponent;
});

const CreateForm = lazy(() => {
  let CreateFormComponent;
  startTransition(() => {
    CreateFormComponent = import("./views/forms/Create");
  });
  return CreateFormComponent;
});

const EditForm = lazy(() => {
  let EditFormComponent;
  startTransition(() => {
    EditFormComponent = import("./views/forms/Edit");
  });
  return EditFormComponent;
});

const CloneForm = lazy(() => {
  let CloneFormComponent;
  startTransition(() => {
    CloneFormComponent = import("./views/forms/Clone");
  });
  return CloneFormComponent;
});

const FormSubmissions = lazy(() => {
  let FormSubmissionsComponent;
  startTransition(() => {
    FormSubmissionsComponent = import("./views/forms/Submissions");
  });
  return FormSubmissionsComponent;
});

const Users = lazy(() => {
  let UsersComponent;
  startTransition(() => {
    UsersComponent = import("./views/users/Index");
  });
  return UsersComponent;
});

const CreateUser = lazy(() => {
  let CreateUserComponent;
  startTransition(() => {
    CreateUserComponent = import("./views/users/Create");
  });
  return CreateUserComponent;
});

const EditUser = lazy(() => {
  let EditUserComponent;
  startTransition(() => {
    EditUserComponent = import("./views/users/Edit");
  });
  return EditUserComponent;
});

const Profile = lazy(() => {
  let ProfileComponent;
  startTransition(() => {
    ProfileComponent = import("./views/auth/Profile");
  });
  return ProfileComponent;
});

const FormVisualization = lazy(() => {
  let FormVisualizationComponent;
  startTransition(() => {
    FormVisualizationComponent = import("./views/forms/Visualization");
  });
  return FormVisualizationComponent;
});

const FormTypes = lazy(() => {
  let FormTypesComponent;
  startTransition(() => {
    FormTypesComponent = import("./views/formTypes/Index");
  });
  return FormTypesComponent;
});

const App = () => {
  const lang = localStorage.getItem("lang") ?? "en";
  UseLanguageDetectorHook(lang);
  // const { isColorModeSet, setColorMode } = useColorModes(
  //   "coreui-free-react-admin-template-theme",
  // );
  // const storedTheme = useSelector((state) => state.theme);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  //   const theme = urlParams.has("theme")
  //     ? urlParams.get("theme").match(/^[A-Za-z0-9\s]+/)[0]
  //     : null;
  //   if (theme) {
  //     setColorMode(theme);
  //   }
  //
  //   if (isColorModeSet()) {
  //     return;
  //   }
  //
  //   setColorMode(storedTheme);
  // }, [isColorModeSet, setColorMode, storedTheme]);

  return (
    <Suspense>
      <Routes>
        {/* Public Routes */}
        <Route
          exact
          path="/render-form"
          name="Render Form"
          element={<RenderForm />}
        />
        <Route
          exact
          path="/create-form"
          name="Create Form"
          element={<CreateFormIframe />}
        />
        <Route
          exact
          path="/FormSubmissionsOverview"
          name="Get Submissions"
          element={<GetSubmissions />}
        />
        <Route
          path="/edit-form"
          name="Edit Form"
          element={<EditFormIframe />}
        />
        <Route path="*" element={<NoMatch />} />
        <Route exact path="/auth/login" name="Login" element={<Login />} />
        <Route
          exact
          path="/auth/forget_password"
          name="Forget Password"
          element={<ForgetPassword />}
        />
        <Route
          exact
          path="/auth/reset_password/:token"
          name="Rest Password"
          element={<ResetPassword />}
        />

        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/" name="Dashboard" element={<Dashboard />} />
          {/* Tenant Routes */}
          <Route path="tenants" name="Tenants" element={<Tenant />} />
          <Route
            path="tenants/create"
            name="Create Tenant"
            element={<CreateTenant />}
          />
          <Route
            path="tenants/edit/:id"
            name="Edit Tenant"
            element={<EditTenant />}
          />

          {/* Forms Routes */}
          <Route path="forms" name="Forms" element={<Forms />} />
          <Route
            path="forms/create"
            name="Create Form"
            element={<CreateForm />}
          />
          <Route
            path="forms/edit/:form_id"
            name="Edit Form"
            element={<EditForm />}
          />
          <Route
            path="forms/clone/:form_id"
            name="Clone Form"
            element={<CloneForm />}
          />
          <Route
            path="forms/submissions/:form_id"
            name="Form Submissions"
            element={<FormSubmissions />}
          />

          <Route
            path="forms/visualization/:form_id"
            name="Form Visualization"
            element={<FormVisualization />}
          />

          {/* Users Routes */}
          <Route path="users" name="Users" element={<Users />} />
          <Route
            path="users/create"
            name="Create User"
            element={<CreateUser />}
          />
          <Route
            path="users/edit/:user_id"
            name="Edit User"
            element={<EditUser />}
          />
          <Route
            path="form-types"
            name="Form Types"
            element={<FormTypes />}
          />
          <Route path="auth/profile" name="Profile" element={<Profile />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
