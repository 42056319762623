import { AppHeader, AppSidebar } from "../layouts/AppLayout";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100">
        <AppHeader />
        <Outlet />
      </div>
    </div>
  )
}

export default Layout
