// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const language = {
  language: "ar",
  i18n: {
    ar: {
      Display: "العرض",
      Validation: "التصديق",
      Conditional: "الشروط",
      Basic: "أساسي",
      "Text Field": "حقل النص",
      "Text Area": "مساحة النص",
      Number: "رقم",
      Password: "كلمة المرور",
      Checkbox: "خانة اختيار",
      "Select Boxes": "مربعات التحديد",
      Radio: "راديو",
      Button: "زر",
      Advanced: "متقدم",
      Email: "البريد الإلكتروني",
      Url: "الرابط",
      "Phone Number": "رقم الهاتف",
      Tags: "العلامات",
      Address: "العنوان",
      "Date / Time": "التاريخ / الوقت",
      Currency: "العملة",
      Survey: "استطلاع",
      Signature: "التوقيع",
      "HTML Element": "عنصر HTML",
      Content: "المحتوى",
      Columns: "الأعمدة",
      "Field Set": "مجموعة الحقول",
      Panel: "لوحة",
      Table: "جدول",
      Tabs: "علامات التبويب",
      Well: "بئر",
      Hidden: "مخفي",
      Container: "حاوية",
      "Data Map": "خريطة البيانات",
      "Data Grid": "شبكة البيانات",
      "Edit Grid": "تحرير الشبكة",
      Label: "التسمية",
      "Label Position": "موضع التسمية",
      Placeholder: "نص العنصر النائب",
      Description: "الوصف",
      Tooltip: "تلميح الأدوات",
      Prefix: "بادئة",
      Suffix: "لاحقة",
      Widget: "القطعة",
      "Input Mask": "قناع الإدخال",
      "Display Mask": "قناع العرض",
      "Apply Mask On": "تطبيق القناع على",
      "Allow Multiple Masks": "السماح بأقنعة متعددة",
      "Custom CSS Class": "فئة CSS مخصصة",
      "Tab Index": "فهرس التبويب",
      Autocomplete: "الإكمال التلقائي",
      "Hide Label": "إخفاء التسمية",
      "Show Word Counter": "إظهار عداد الكلمات",
      "Show Character Counter": "إظهار عداد الحروف",
      "Hide Input": "إخفاء الإدخال",
      "Initial Focus": "التركيز الأولي",
      "Allow Spellcheck": "السماح بالتدقيق الإملائي",
      Disabled: "معطل",
      "Table View": "عرض الجدول",
      "Modal Edit": "تحرير النمط",
      Preview: "عرض",
      "Description for this field.": "وصف هذا العنصر",
      "To add a tooltip to this field, enter text here.":
        "لإضافة تلميح أداة إلى هذا الحقل، أدخل النص هنا",
      "The label for this field that will appear next to it.":
        "التسمية لهذا الحقل التي ستظهر بجانبه.",
      "Position for the label for this field.": "موضع التسمية لهذا الحقل.",
      "The placeholder text that will appear when this field is empty.":
        "نص العنصر النائب الذي سيظهر عندما يكون هذا الحقل فارغًا.",
      "The description is text that will appear below the input field.":
        "الوصف هو النص الذي سيظهر أسفل حقل الإدخال.",
      "Adds a tooltip to the side of this field.":
        "يضيف تلميح الأدوات إلى جانب هذا الحقل.",
      "Custom CSS class to add to this component.":
        "فئة CSS مخصصة لإضافتها إلى هذا المكون.",
      "Sets the tabindex attribute of this component to override the tab order of the form. See the MDN documentation on tabindex for more information.":
        "يضبط خاصية tabindex لهذا المكون لتجاوز ترتيب التبويب للنموذج. انظر توثيق MDN حول tabindex لمزيد من المعلومات.",
      "A hidden field is still a part of the form, but is hidden from view.":
        "الحقل المخفي لا يزال جزءًا من النموذج، ولكنه مخفي عن العرض.",
      "Hide the label (title, if no label) of this component. This allows you to show the label in the form builder, but not when it is rendered.":
        "إخفاء التسمية (العنوان، إذا لم يكن هناك تسمية) لهذا المكون. هذا يسمح لك بعرض التسمية في منشئ النموذج، ولكن ليس عند العرض.",
      "Make this field the initially focused element on this form.":
        "اجعل هذا الحقل العنصر الذي يتم التركيز عليه أولاً في هذا النموذج.",
      "Disable the form input.": "تعطيل إدخال النموذج.",
      "Shows this value within the table view of the submissions.":
        "يعرض هذه القيمة في عرض الجدول للإرسالات.",
      "Opens up a modal to edit the value of this component.":
        "يفتح نموذجًا لتحرير قيمة هذا المكون.",
      "Allows multiple values to be entered for this field.":
        "يسمح بإدخال قيم متعددة لهذا الحقل.",
      "The Default Value will be the value for this field, before user interaction. Having a default value will override the placeholder text.":
        "القيمة الافتراضية ستكون القيمة لهذا الحقل، قبل تفاعل المستخدم. وجود قيمة افتراضية سيتجاوز نص العنصر النائب.",
      "Multiple Values": "قيم متعددة",
      "Default Value": "القيمة الافتراضية",
      Persistent: "ثابت",
      None: "لا شيء",
      Server: "الخادم",
      Client: "العميل",
      "Input Format": "تنسيق الإدخال",
      Protected: "محمي",
      "Database Index": "فهرس قاعدة البيانات",
      "Text Case": "حالة النص",
      "Mixed (Allow upper and lower case)":
        "مختلط (يسمح بالأحرف الكبيرة والصغيرة)",
      Uppercase: "أحرف كبيرة",
      Lowercase: "أحرف صغيرة",
      "Truncate Multiple Spaces": "اقتطاع المسافات المتعددة",
      Encrypted: "مشفر",
      "Redraw On": "إعادة الرسم على",
      "Clear Value When Hidden": "مسح القيمة عند الإخفاء",
      "Calculate Value on server": "حساب القيمة على الخادم",
      "Allow Manual Override of Calculated Value":
        "السماح بتجاوز يدوي للقيمة المحسوبة",
      "A required field must be filled in before the form can be submitted.":
        "يجب ملء الحقل المطلوب قبل إرسال النموذج.",
      "Makes sure the data submitted for this field is unique, and has not been submitted before.":
        "يضمن أن البيانات المقدمة لهذا الحقل فريدة، ولم يتم تقديمها من قبل.",
      "Determines when this component should trigger front-end validation.":
        "يحدد متى يجب أن يؤدي هذا المكون إلى التحقق من صحة الواجهة الأمامية.",
      "The label for this field when an error occurs.":
        "التسمية لهذا الحقل عند حدوث خطأ.",
      "Error message displayed if any error occurred.":
        "رسالة الخطأ التي يتم عرضها إذا حدث أي خطأ.",
      "Check this if you wish to perform the validation ONLY on the server side. This keeps your validation logic private and secret.":
        "حدد هذا إذا كنت ترغب في إجراء التحقق من الصحة فقط على جانب الخادم. هذا يحافظ على منطق التحقق الخاص بك خاص وسري.",
      "Validate On": "التحقق على",
      Change: "تغيير",
      Blur: "طمس",
      "Minimum Length": "الطول الأدنى",
      "Maximum Length": "الطول الأقصى",
      "Minimum Word Length": "الحد الأدنى لطول الكلمة",
      "Maximum Word Length": "الحد الأقصى لطول الكلمة",
      "Regular Expression Pattern": "نمط التعبير العادي",
      "Error Label": "تسمية الخطأ",
      "Custom Error Message": "رسالة خطأ مخصصة",
      "Custom Validation": "التحقق المخصص",
      "The following variables are available in all scripts:":
        "المتغيرات التالية متاحة في جميع البرامج النصية:",
      input: "الإدخال",
      "The value that was input into this component":
        "القيمة التي تم إدخالها في هذا المكون",
      form: "النموذج",
      "The complete form JSON object": "كائن JSON للنموذج الكامل",
      submission: "الإرسال",
      "The complete submission object.": "كائن الإرسال الكامل.",
      data: "البيانات",
      "The complete submission data object.": "كائن بيانات الإرسال الكامل.",
      row: "صف",
      component: "",
      Component: "",
      "The current component JSON": "كائن JSON للمكون الحالي",
      instance: "الحالة",
      "The current component instance.": "الحالة الحالية للمكون.",
      value: "القيمة",
      "The current value of the component.": "القيمة الحالية للمكون.",
      moment: "اللحظة",
      "The moment.js library for date manipulation":
        "مكتبة moment.js للتلاعب بالتواريخ",
      _: "مثال Lodash",
      "An instance of Lodash.": "مثال Lodash.",
      utils: "الأدوات",
      "An instance of the FormioUtils object.": "مثال لكائن FormioUtils.",
      util: "الأدوات",
      "An alias for 'utils'.": "اسم مستعار لـ 'الأدوات'.",
      "Enter custom validation code.": "أدخل رمز التحقق المخصص.",
      "You must assign the valid variable as either true or an error message if validation fails.":
        "يجب تعيين متغير valid على أنه true أو رسالة خطأ إذا فشل التحقق.",
      "Secret Validation": "التحقق السري",
      "Execute custom logic using JSONLogic.":
        "تنفيذ منطق مخصص باستخدام JSONLogic.",
      "You can set the following keys (among others):":
        "يمكنك تعيين المفاتيح التالية (من بين أمور أخرى):",
      required: "مطلوب",
      min: "الحد الأدنى",
      max: "الحد الأقصى",
      minLength: "الحد الأدنى للطول",
      maxLength: "الحد الأقصى للطول",
      minWords: "الحد الأدنى للكلمات",
      maxWords: "الحد الأقصى للكلمات",
      invalid_email: "البريد الإلكتروني غير صالح",
      invalid_date: "التاريخ غير صالح",
      invalid_day: "اليوم غير صالح",
      invalid_regex: "التعبير العادي غير صالح",
      mask: "قناع",
      pattern: "نمط",
      custom: "مخصص",
      "Depending on the error message some of the following template variables can be used in the script:":
        "اعتمادًا على رسالة الخطأ، يمكن استخدام بعض متغيرات القالب التالية في البرنامج النصي:",
      "is replaced with the label of the field.": "يتم استبدال بتسمية الحقل.",
      "Type to search": "ابحث",
      Top: "أعلى",
      "Left (Left-aligned)": "يسار (محاذاة لليسار)",
      "Left (Right-aligned)": "يسار (محاذاة لليمين)",
      "Right (Left-aligned)": "يمين (محاذاة لليسار)",
      "Right (Right-aligned)": "يمين (محاذاة لليمين)",
      Bottom: "أسفل",
      "JSONLogic Validation": "التحقق من صحة JSONLogic",
      "Custom Errors": "تخصيص خطأ",
      "Drag and Drop a form component": "قم بسحب وإسقاط المكون",
      "HTML Attributes": "سمات HTML",
      "Attribute Name": "اسم السمة",
      "Attribute Value": "قيمة السمة",
      "Add Attribute": "إضافة سمة",
      "Provide a map of HTML attributes for component's input element (attributes provided by other component settings or other attributes generated by form.io take precedence over attributes in this grid)":
        "توفير خريطة لسمات HTML لعنصر إدخال المكون (السمات المقدمة من إعدادات المكونات الأخرى أو السمات الأخرى التي تم إنشاؤها بواسطة form.io لها الأولوية على السمات في هذه الشبكة)",
      Style: "نمط",
      "Custom styles that should be applied to this component when rendered in PDF.":
        "أنماط مخصصة يجب تطبيقها على هذا المكون عند عرضه في PDF.",
      Page: "صفحة",
      Left: "يسار",
      Right: "يمين",
      "The top margin within a page to place this component.":
        "الهامش العلوي داخل الصفحة لوضع هذا المكون.",
      Width: "عرض",
      "The width of the component (in pixels).": "عرض المكون (بالبكسل).",
      Height: "ارتفاع",
      "The height of the component (in pixels).": "ارتفاع المكون (بالبكسل).",
      "Advanced Logic": "المنطق المتقدم",
      "Advanced Logic Configured": "تم تكوين المنطق المتقدم",
      "Logic Name": "اسم المنطق",
      Trigger: "محفز",
      Type: "نوع",
      Actions: "إجراءات",
      "Add Action": "إضافة إجراء",
      "Save Logic": "حفظ المنطق",
      actions: "الإجراءات",
      "Action Name": "اسم الإجراء",
      "Save Action": "حفظ الإجراء",
      Simple: "بسيط",
      "This component should Display:": "يجب أن يعرض هذا المكون:",
      "When the form component:": "عندما يكون مكون النموذج:",
      "Has the value:": "لديه القيمة:",
      "The following variables are available in all scripts.":
        "المتغيرات التالية متاحة في جميع البرامج النصية.",
      "The moment.js library for date manipulation.":
        "مكتبة moment.js للتلاعب بالتواريخ.",
      "Enter custom javascript code.": "أدخل رمز جافاسكريبت مخصص.",
      "You must assign the show variable a boolean result.":
        "يجب تعيين المتغير show على نتيجة بوليانية.",
      "Note: Advanced Conditional logic will override the results of the Simple Conditional logic.":
        "ملاحظة: سيؤدي منطق الشرط المتقدم إلى تجاوز نتائج منطق الشرط البسيط.",
      Example: "مثال",
      "Example:": "مثال:",
      "Full Lodash support is provided using an '_' before each operation, such as":
        "يتم توفير دعم كامل لـ Lodash باستخدام '_' قبل كل عملية، مثل",
      "Click here for an example": "انقر هنا للحصول على مثال",
      API: "واجهة برمجة التطبيقات",
      Logic: "المنطق",
      "Advanced Conditions": "شروط متقدمة",
      "Add Logic": "اضافة منطق",
      "PDF Overlay": "تركيب PDF",
      "The settings inside apply only to the PDF forms":
        "تنطبق الإعدادات الموجودة بالداخل فقط على نماذج PDF",
      "The PDF page to place this component.": "صفحة PDF لوضع هذا المكون.",
      "The left margin within a page to place this component.":
        "الهامش الأيسر داخل الصفحة لوضع هذا المكون.",
      "This allows you to configure any custom properties for this component.":
        "يسمح لك هذا بتكوين أي خصائص مخصصة لهذا المكون.",
      "The name of this field in the API endpoint.":
        "اسم هذا الحقل في نقطة نهاية API.",
      "Tag the field for use in custom logic.":
        "وضع علامة على الحقل لاستخدامه في المنطق المخصص.",
      "Add Another": "إضافة آخر",
      Key: "المفتاح",
      Value: "القيمة",
      "Property Name": "اسم الخاصية",
      "Field Tags": "العلامات الميدانية",
      "Custom Properties": "تخصيص خصائص",
      "The settings inside apply only to the PDF forms.":
        "تنطبق الإعدادات الموجودة بالداخل فقط على نماذج PDF",
      "A persistent field will be stored in database when the form is submitted.":
        "سيتم تخزين الحقل الثابت في قاعدة البيانات عند إرسال النموذج.",
      "Force the output of this field to be sanitized in a specific format.":
        "فرض تنسيق مخرجات هذا الحقل بتنسيق محدد.",
      "A protected field will not be returned when queried via API.":
        "لن يتم إرجاع الحقل المحمي عند الاستعلام عبر API.",
      "Set this field as an index within the database. Increases performance for submission queries.":
        "قم بتعيين هذا الحقل كمؤشر داخل قاعدة البيانات. يزيد من أداء استعلامات الإرسال.",
      "Only available with Security Module. Contact sales@form.io for more information.":
        "متاح فقط مع وحدة الأمان. اتصل بـ sales@form.io لمزيد من المعلومات.",
      "Redraw this component if another component changes. This is useful if interpolating parts of the component like the label.":
        "أعد رسم هذا المكون إذا تغير مكون آخر. هذا مفيد إذا كان يتم استبدال أجزاء من المكون مثل التسمية.",
      "When a field is hidden, clear the value.": "عند إخفاء حقل، امسح القيمة.",
      "Checking this will run the calculation on the server. This is useful if you wish to override the values submitted with the calculations performed on the server.":
        "سيؤدي تحديد هذا إلى تشغيل الحساب على الخادم. هذا مفيد إذا كنت ترغب في تجاوز القيم المقدمة مع الحسابات التي يتم إجراؤها على الخادم.",
      "When checked, this will allow the user to manually override the calculated value.":
        "عند التحديد، سيسمح هذا للمستخدم بتجاوز القيمة المحسوبة يدويًا.",
      "The minimum length requirement this field must meet.":
        "متطلب الطول الأدنى الذي يجب أن يفي به هذا الحقل.",
      "The maximum length requirement this field must meet.":
        "متطلب الطول الأقصى الذي يجب أن يفي به هذا الحقل.",
      "The minimum amount of words that can be added to this field.":
        "الحد الأدنى لعدد الكلمات التي يمكن إضافتها إلى هذا الحقل.",
      "The maximum amount of words that can be added to this field.":
        "الحد الأقصى لعدد الكلمات التي يمكن إضافتها إلى هذا الحقل.",
      "The regular expression pattern test that the field value must pass before the form can be submitted.":
        "اختبار نمط التعبير العادي الذي يجب أن تجتازه قيمة الحقل قبل إرسال النموذج.",
      "Contextual 'row' data, used within DataGrid, EditGrid, and Container components":
        "بيانات 'صف' سياقية، تُستخدم داخل مكونات DataGrid وEditGrid وContainer",
      "This allows you to set different custom error messages for different errors (in contrast to 'Custom Error Message', which only allows you to set one error message for all errors). E.g.":
        "يسمح لك هذا بتعيين رسائل خطأ مخصصة مختلفة لأخطاء مختلفة (على عكس 'رسالة الخطأ المخصصة'، التي تسمح لك بتعيين رسالة خطأ واحدة لجميع الأخطاء). على سبيل المثال",
      Rows: "صفوف",
      "This allows control over how many rows are visible in the text area.":
        "يسمح لك هذا بالتحكم في عدد الصفوف الظاهرة في مساحة النص.",
      "Select the type of applying mask.": "حدد نوع تطبيق القناع.",
      "Widget Settings": "إعدادات القطعة",
      Editor: "محرر",
      "Select the type of WYSIWYG editor to use for this text area.":
        "حدد نوع محرر WYSIWYG لاستخدامه في مساحة النص هذه.",
      "Auto Expand": "التوسع التلقائي",
      "This will make the TextArea auto expand it's height as the user is typing into the area.":
        "سيجعل هذا مساحة النص تتوسع تلقائيًا في ارتفاعها أثناء كتابة المستخدم في المنطقة.",
      "Show a live count of the number of words.": "عرض عد مباشر لعدد الكلمات.",
      "Show a live count of the number of characters.":
        "عرض عد مباشر لعدد الأحرف.",
      "When data is entered, you can change the case of the value.":
        "عند إدخال البيانات، يمكنك تغيير حالة القيمة.",
      "Hide the input in the browser. This does not encrypt on the server. Do not use for passwords.":
        "إخفاء الإدخال في المتصفح. لا يتم تشفير هذا على الخادم. لا تستخدم لكلمات المرور.",
      "Use Thousands Separator": "استخدام فاصل الآلاف",
      "Separate thousands by local delimiter.": "فصل الآلاف بفاصل محلي.",
      "Require Decimal": "يتطلب فاصلة عشرية",
      "Always show decimals, even if trailing zeros.":
        "عرض الفواصل العشرية دائمًا، حتى لو كانت الأصفار اللاحقة.",
      "Minimum Value": "القيمة الأدنى",
      "The minimum value this field must have before the form can be submitted.":
        "القيمة الأدنى التي يجب أن يمتلكها هذا الحقل قبل أن يتم إرسال النموذج.",
      "Maximum Value": "القيمة الأقصى",
      "The maximum value this field can have before the form can be submitted.":
        "القيمة القصوى التي يمكن أن يمتلكها هذا الحقل قبل أن يتم إرسال النموذج.",
      "Password fields are automatically encrypted using 1-way salted bcrypt hashes. These hashes are also protected and not returned in the API.":
        "يتم تشفير حقول كلمات المرور تلقائيًا باستخدام التجزئات المشفرة ذات الاتجاه الواحد المملحة بـ bcrypt. هذه التجزئات محمية أيضًا ولا يتم إرجاعها في واجهة برمجة التطبيقات.",
      "Label Width": "عرض التسمية",
      "The width of label on line in percentages.":
        "عرض التسمية على الخط بالنسبة المئوية.",
      "Label Margin": "هامش التسمية",
      "The width of label margin on line in percentages.":
        "عرض هامش التسمية على الخط بالنسبة المئوية.",
      Shortcut: "اختصار",
      "Shortcut for this component.": "اختصار لهذا المكون.",
      "Input Type": "نوع الإدخال",
      "This is the input type used for this checkbox.":
        "هذا هو نوع الإدخال المستخدم في مربع الاختيار هذا.",
      "Options Label Position": "موضع تسمية الخيارات",
      "Position for the label for options for this field.":
        "موضع التسمية لخيارات هذا الحقل.",
      "Inline Layout": "تخطيط مضمّن",
      "Displays the checkboxes/radios horizontally.":
        "يعرض مربعات الاختيار / الراديو أفقيًا.",
      "Data Source Type": "نوع مصدر البيانات",
      "The source to use for the select data. Values lets you provide your own values and labels. JSON lets you provide raw JSON data. URL lets you provide a URL to retrieve the JSON data from.":
        "المصدر لاستخدامه في تحديد البيانات. القيم تتيح لك تقديم قيمك وعلاماتك الخاصة. JSON يتيح لك تقديم بيانات JSON الأولية. URL يتيح لك تقديم عنوان URL لاسترداد بيانات JSON منه.",
      Values: "القيم",
      "The radio button values that can be picked for this field. Values are text submitted with the form data. Labels are text that appears next to the radio buttons on the form.":
        "قيم أزرار الراديو التي يمكن اختيارها لهذا الحقل. القيم هي النص المقدم مع بيانات النموذج. العلامات هي النص الذي يظهر بجانب أزرار الراديو على النموذج.",
      "The shortcut key for this option.": "مفتاح الاختصار لهذا الخيار.",
      "Allow only available values": "السماح بالقيم المتاحة فقط",
      "Check this if you would like to perform a validation check to ensure the selected value is an available option.":
        "حدد هذا إذا كنت ترغب في إجراء فحص التحقق للتأكد من أن القيمة المحددة هي خيار متاح.",
      "Minimum checked number": "الحد الأدنى من العدد المحدد",
      "Minimum checkboxes required before form can be submitted.":
        "الحد الأدنى لمربعات الاختيار المطلوبة قبل إرسال النموذج.",
      "Maximum checked number": "الحد الأقصى من العدد المحدد",
      "Maximum checkboxes possible before form can be submitted.":
        "الحد الأقصى لمربعات الاختيار الممكنة قبل إرسال النموذج.",
      "Minimum checked error message": "رسالة خطأ الحد الأدنى من العدد المحدد",
      "Error message displayed if minimum number of items not checked.":
        "رسالة الخطأ التي يتم عرضها إذا لم يتم تحديد الحد الأدنى لعدد العناصر.",
      "Maximum checked error message": "رسالة خطأ الحد الأقصى من العدد المحدد",
      "Error message displayed if maximum number of items checked.":
        "رسالة الخطأ التي يتم عرضها إذا تم تحديد الحد الأقصى لعدد العناصر.",
      "Widget Type": "نوع القطعة",
      "Select the type of widget you'd like to use.":
        "حدد نوع القطعة التي ترغب في استخدامها.",
      "Unique Options": "خيارات فريدة",
      "Display only unique dropdown options.":
        "عرض خيارات القائمة المنسدلة الفريدة فقط.",
      "Data Source Values": "قيم مصدر البيانات",
      "Values to use as the data source. Labels are shown in the select field. Values are the corresponding values saved with the submission.":
        "القيم لاستخدامها كمصدر للبيانات. يتم عرض العلامات في حقل التحديد. القيم هي القيم المقابلة المحفوظة مع الإرسال.",
      "Storage Type": "نوع التخزين",
      "The type to store the data. If you select something other than autotype, it will force it to that type.":
        "النوع لتخزين البيانات. إذا حددت شيئًا غير النوع التلقائي، فسوف يفرض عليه هذا النوع.",
      "ID Path": "مسار المعرف",
      "Path to the select option id.": "مسار إلى معرف خيار التحديد.",
      "Item Template": "قالب العنصر",
      "The HTML template for the result data items.":
        "قالب HTML لعناصر بيانات النتيجة.",
      "Refresh Options On": "تحديث الخيارات عند",
      "Refresh Options On Blur": "تحديث الخيارات عند الطمس",
      "Clear Value On Refresh Options": "مسح القيمة عند تحديث الخيارات",
      "Enable Static Search": "تمكين البحث الثابت",
      "Search Threshold": "عتبة البحث",
      "Read Only Value": "قيمة للقراءة فقط",
      "Choices.js options": "خيارات Choices.js",
      "Use exact search": "استخدام البحث الدقيق",
      "Refresh data when another field changes.":
        "تحديث البيانات عند تغيير حقل آخر.",
      "Refresh data when another field is blured.":
        "تحديث البيانات عند طمس حقل آخر.",
      "When the Refresh On field is changed, clear this components value.":
        "عند تغيير حقل التحديث، امسح قيمة هذا المكون.",
      "When checked, the select dropdown will allow for searching within the static list of items provided.":
        "عند التحديد، ستسمح القائمة المنسدلة بالتحديد بالبحث داخل القائمة الثابتة للعناصر المقدمة.",
      "At what point does the match algorithm give up. A threshold of 0.0 requires a perfect match, a threshold of 1.0 would match anything.":
        "في أي نقطة يتخلى خوارزمية المطابقة. يتطلب حد 0.0 تطابقًا تامًا، في حين أن حد 1.0 سيتطابق مع أي شيء.",
      "Check this if you would like to show just the value when in Read Only mode.":
        "حدد هذا إذا كنت ترغب في عرض القيمة فقط عند الوضع للقراءة فقط.",
      "A raw JSON object to use as options for the Select component (Choices JS).":
        "كائن JSON خام لاستخدامه كخيارات لمكون التحديد (Choices JS).",
      "Disables search algorithm threshold.": "يعطل عتبة خوارزمية البحث.",
      Action: "الإجراء",
      "Save On Enter": "حفظ عند الضغط على زر الإدخال",
      Theme: "السمة",
      Size: "الحجم",
      "Block Button": "زر كتلة",
      "Left Icon": "أيقونة اليسار",
      "Right Icon": "أيقونة اليمين",
      "Enter icon classes": "أدخل فئات الأيقونة",
      "This is the action to be performed by this button.":
        "هذا هو الإجراء الذي سيتم تنفيذه بواسطة هذا الزر.",
      "Use the Enter key to submit form.":
        "استخدم مفتاح الإدخال لتقديم النموذج.",
      "The color theme of this button.": "السمة اللونية لهذا الزر.",
      "The size of this button.": "حجم هذا الزر.",
      "This control should span the full width of the bounding container.":
        "يجب أن يمتد هذا التحكم بعرض الحاوية المحيطة بالكامل.",
      "This is the full icon class string to show the icon. Example: 'fa fa-plus'":
        "هذه هي سلسلة فئة الأيقونة الكاملة لعرض الأيقونة. مثال: 'fa fa-plus'",
      "Disable on Form Invalid": "تعطيل عند عدم صلاحية النموذج",
      "This will disable this field if the form is invalid.":
        "سيؤدي هذا إلى تعطيل هذا الحقل إذا كان النموذج غير صالح.",
      Enable: "تمكين",
      "Enable Kickbox validation for this email field.":
        "تمكين التحقق من صحة البريد الإلكتروني عبر Kickbox لهذا الحقل.",
      "Validate this email using the Kickbox email validation service.":
        "تحقق من صحة هذا البريد الإلكتروني باستخدام خدمة التحقق من البريد الإلكتروني Kickbox.",
      "Input Mask Placeholder Char": "حرف العنصر النائب لقناع الإدخال",
      "You can specify a char which will be used as a placeholder in the field.E.g., ˍMake note that placeholder char will be replaced by a space if it is used inside the mask":
        "يمكنك تحديد حرف سيتم استخدامه كعنصر نائب في الحقل. مثال: ˍ لاحظ أن حرف العنصر النائب سيتم استبداله بمسافة إذا تم استخدامه داخل القناع",
      Delimiter: "الفاصل",
      "What is used to separate the tags.": "ما يتم استخدامه لفصل العلامات.",
      "Max Tags": "الحد الأقصى للعلامات",
      "The maximum amount of tags that can be added. 0 for infinity.":
        "الحد الأقصى لعدد العلامات التي يمكن إضافتها. 0 للانهائية.",
      "Store As": "تخزين كـ",
      Provider: "المزود",
      "Which address search service should be used.":
        "أي خدمة بحث عن العنوان يجب استخدامها.",
      "Select your address search provider":
        "اختر مزود البحث عن العنوان الخاص بك",
      "Manual Mode View String": "سلسلة عرض الوضع اليدوي",
      "Specify template which should be when quering view string for the component value entered in manual mode. This string is used in table view, CSV export and email rendering. When left blank combined value of all components joined with comma will be used.":
        "حدد القالب الذي يجب استخدامه عند استعلام سلسلة العرض لقيمة المكون المدخلة في الوضع اليدوي. تُستخدم هذه السلسلة في عرض الجدول، تصدير CSV، وعرض البريد الإلكتروني. عند تركها فارغة، سيتم استخدام القيمة المجمعة لجميع المكونات المنضمة بفاصلة.",
      '"address" variable references component value, "data" - submission data and "component" - address component schema.':
        'يشير متغير "العنوان" إلى قيمة المكون، و"البيانات" - بيانات الإرسال و"المكون" - مخطط مكون العنوان.',
      "Enable Manual Mode": "تمكين الوضع اليدوي",
      "Should Manual Mode be enabled for that component or not.":
        "هل يجب تمكين الوضع اليدوي لهذا المكون أم لا.",
      "Switch To Manual Mode Label":
        "التسمية لمربع الاختيار للتحويل إلى الوضع اليدوي",
      "The label for the checkbox used to switch to manual mode.":
        "التسمية لمربع الاختيار المستخدم للتحويل إلى الوضع اليدوي.",
      "Can't find address? Switch to manual mode.":
        "لا تستطيع العثور على العنوان؟ انتقل إلى الوضع اليدوي.",
      "Disable Clear Icon": "تعطيل أيقونة المسح",
      "Clear Icon allows easily clear component's value.":
        "تسمح أيقونة المسح بسهولة مسح قيمة المكون.",
      "Display in Timezone": "عرض في المنطقة الزمنية",
      "This will display the captured date time in the select timezone.":
        "سيعرض هذا التاريخ والوقت الملتقط في المنطقة الزمنية المحددة.",
      "Use Locale Settings": "استخدام إعدادات اللغة",
      "Use locale settings to display date and time.":
        "استخدام إعدادات اللغة لعرض التاريخ والوقت.",
      "Allow Manual Input": "السماح بالإدخال اليدوي",
      "Check this if you would like to allow the user to manually enter in the date.":
        "حدد هذا إذا كنت ترغب في السماح للمستخدم بإدخال التاريخ يدويًا.",
      Format: "التنسيق",
      "The date format for displaying the datetime value.":
        "تنسيق التاريخ لعرض قيمة التاريخ والوقت.",
      "Use formats provided by": "استخدام التنسيقات المقدمة من",
      "Shortcut Buttons": "أزرار الاختصار",
      onClick: "عند النقر",
      "You can specify few buttons which will be shown above the calendar. Use Label to specify the name of the button and onClick to specify which date/time will be set when user clicks the button. E.g, date = new Date()":
        "يمكنك تحديد بعض الأزرار التي ستظهر أعلى التقويم. استخدم التسمية لتحديد اسم الزر واستخدم عند النقر لتحديد التاريخ/الوقت الذي سيتم تعيينه عند نقر المستخدم على الزر. على سبيل المثال: date = new Date()",
      "Enable Date Input": "تمكين إدخال التاريخ",
      "Enables date input for this field.": "يُمكّن إدخال التاريخ لهذا الحقل.",
      "Disable specific dates or dates by range":
        "تعطيل تواريخ محددة أو تواريخ بنطاق معين",
      "Add dates that you want to blacklist. For example:\n\n2025-02-21":
        "أضف التواريخ التي تريد إدراجها في القائمة السوداء. على سبيل المثال:",
      "Custom Disabled Dates": "تواريخ مخصصة معطلة",
      "Disable weekends": "تعطيل عطلات نهاية الأسبوع",
      "Check to disable weekends": "حدد لتعطيل عطلات نهاية الأسبوع",
      "Disable weekdays": "تعطيل أيام الأسبوع",
      "Check to disable weekdays": "حدد لتعطيل أيام الأسبوع",
      "Enable Time Input": "تمكين إدخال الوقت",
      "Hour Step Size": "حجم خطوة الساعة",
      "Minute Step Size": "حجم خطوة الدقيقة",
      "12 Hour Time (AM/PM)": "نظام 12 ساعة (ص/م)",
      Date: "التاريخ",
      "Use Input to add moment.js for minDate":
        "استخدام الإدخال لإضافة moment.js لـ minDate",
      "Use calendar to set minDate": "استخدام التقويم لتعيين minDate",
      "Use Input to add moment.js for maxDate":
        "استخدام الإدخال لإضافة moment.js لـ maxDate",
      "Use calendar to set maxDate": "استخدام التقويم لتعيين maxDate",
      "Default Date": "التاريخ الافتراضي",
      "Flatpickr options": "خيارات Flatpickr",
      "You can use Moment.js functions to set the default value to a specific date. For example:":
        "يمكنك استخدام دوال Moment.js لتعيين القيمة الافتراضية لتاريخ محدد. على سبيل المثال:",
      "A raw JSON object to use as options for the Date / Time component (Flatpickr).":
        "كائن JSON خام لاستخدامه كخيارات لمكون التاريخ / الوقت (Flatpickr).",
      "Enables to use input for moment functions instead of calendar.":
        "يُمكّن استخدام الإدخال لدوال moment بدلاً من التقويم.",
      "Enables to use calendar to set date.":
        "يُمكّن استخدام التقويم لتعيين التاريخ.",
      "Enables time input for this field.": "يُمكّن إدخال الوقت لهذا الحقل.",
      "The number of hours to increment/decrement in the time picker.":
        "عدد الساعات للزيادة/النقصان في منتقي الوقت.",
      "The number of minutes to increment/decrement in the time picker.":
        "عدد الدقائق للزيادة/النقصان في منتقي الوقت.",
      "Display time in 12 hour time with AM/PM.":
        "عرض الوقت بنظام 12 ساعة مع AM/PM.",
      "Hide Input Labels": "إخفاء تسميات الإدخال",
      "Inputs Label Position": "موضع تسميات الإدخال",
      "Hide the labels of component inputs. This allows you to show the labels in the form builder, but not when it is rendered.":
        "إخفاء تسميات إدخالات المكون. يسمح لك هذا بعرض التسميات في منشئ النموذج، ولكن ليس عند عرضه.",
      "Position for the labels for inputs for this field.":
        "موضع التسميات لإدخالات هذا الحقل.",
      "The placeholder text that will appear when Day field is empty.":
        "نص العنصر النائب الذي سيظهر عندما يكون حقل اليوم فارغًا.",
      "Hide the Day part of the component.": "إخفاء جزء اليوم من المكون.",
      "Day First": "اليوم أولاً",
      "Display the Day field before the Month field.":
        "عرض حقل اليوم قبل حقل الشهر.",
      "Type of input": "نوع الإدخال",
      "The placeholder text that will appear when Month field is empty.":
        "نص العنصر النائب الذي سيظهر عندما يكون حقل الشهر فارغًا.",
      "Minimum Year": "السنة الأدنى",
      "The minimum year that can be entered.":
        "السنة الأدنى التي يمكن إدخالها.",
      "Require Day": "اليوم مطلوب",
      "Require Month": "الشهر مطلوب",
      "Require Year": "السنة مطلوبة",
      "Maximum Day": "اليوم الأقصى",
      "Minimum Day": "اليوم الأدنى",
      "Maximum Year": "السنة القصوى",
      "The maximum year that can be entered.":
        "السنة القصوى التي يمكن إدخالها.",
      "The placeholder text that will appear when Year field is empty.":
        "نص العنصر النائب الذي سيظهر عندما يكون حقل السنة فارغًا.",
      "Data Format": "تنسيق البيانات",
      "The moment.js format for saving the value of this field.":
        "تنسيق moment.js لحفظ قيمة هذا الحقل.",
      Questions: "الأسئلة",
      "The questions you would like to ask in this survey question.":
        "الأسئلة التي ترغب في طرحها في سؤال الاستبيان هذا.",
      "The values that can be selected per question. Example: 'Satisfied', 'Very Satisfied', etc.":
        "القيم التي يمكن اختيارها لكل سؤال. على سبيل المثال: 'راضٍ', 'راضٍ جدًا', إلخ.",

      Submit: "تقديم النموذج",
      Layout: "نموذج",
      Data: "بيانات",
      Premium: "استثنائي",
      Select: "قائمة",
      Remove: "حذف",
      Save: "تخزين",
      Add: "إضافة",
      Edit: "تعديل",
      Search: "بحث",
      "Search field(s)": "بحث في الحقول",
      UnsavedRowsError: "يرجى حفظ جميع الصفوف قبل المتابعة.",
      InvalidRowsError: "يرجى تصحيح الصفوف غير الصالحة قبل المتابعة.",
      InvalidRowError: "صف غير صالح. يرجى تصحيحه أو حذفه.",
      AlertMessageWithLabel: "{{label}}: {{message}}",
      AlertMessage: "{{message}}",
      Complete: "اكتمال التقديم",
      Error: "يرجى تصحيح الأخطاء التالية قبل التقديم.",
      ErrorListHotkey: "اضغط على Ctrl + Alt + X للعودة إلى قائمة الأخطاء.",
      ErrorsListNavigationMessage: "انقر للانتقال إلى الحقل مع الخطأ التالي.",
      SubmitError: "يرجى التحقق من النموذج وتصحيح جميع الأخطاء قبل التقديم.",
      Required: "مطلوب",
      Unique: "يجب أن يكون فريداً",
      Array: "يجب أن يكون مصفوفة",
      ArrayNonempty: "{{field}} يجب أن تكون مصفوفة غير فارغة",
      Nonarray: "يجب ألا تكون مصفوفة",
      Pattern: "لا يتطابق مع النمط {{pattern}}",
      MinLength: "يجب أن يحتوي على ما لا يقل عن {{length}} أحرف.",
      MaxLength: "يجب أن لا يحتوي على أكثر من {{length}} حرف.",
      MinWords: "يجب أن يحتوي على ما لا يقل عن {{length}} كلمات.",
      MaxWords: "يجب ألا يحتوي على أكثر من {{length}} كلمة.",
      Min: "لا يمكن أن يكون أقل من {{min}}.",
      Max: "لا يمكن أن يكون أكبر من {{max}}.",
      MaxDate: "لا يجب أن يحتوي على تاريخ بعد {{- maxDate}}",
      MinDate: "لا يجب أن يحتوي على تاريخ قبل {{- minDate}}",
      MaxYear: "لا يجب أن يحتوي على سنة أكبر من {{maxYear}}",
      MinYear: "لا يجب أن يحتوي على سنة أقل من {{minYear}}",
      InvalidEmail: "يجب أن يكون بريدًا إلكترونيًا صالحًا.",
      InvalidUrl: "يجب أن يكون عنوان URL صالحًا.",
      InvalidRegex: "لا يتطابق مع النمط {{regex}}.",
      InvalidDate: "ليس تاريخًا صالحًا.",
      InvalidDay: "ليس يومًا صالحًا.",
      Mask: "لا يتطابق مع القناع.",
      ValueIsNotAvailable: "قيمة غير صالحة.",
      Stripe: "{{stripe}}",
      Month: "شهر",
      Day: "يوم",
      Year: "سنة",
      month: "شهر",
      day: "يوم",
      year: "سنة",
      January: "يناير",
      February: "فبراير",
      March: "مارس",
      April: "أبريل",
      May: "مايو",
      June: "يونيو",
      July: "يوليو",
      August: "أغسطس",
      September: "سبتمبر",
      October: "أكتوبر",
      November: "نوفمبر",
      December: "ديسمبر",
      Next: "التالي",
      Previous: "السابق",
      Cancel: "إلغاء",
      ConfirmCancel: "هل أنت متأكد أنك تريد الإلغاء؟",
      SaveDraftInstanceError: "لا يمكن حفظ المسودة لأنه لا يوجد نموذج.",
      SaveDraftAuthError:
        "لا يمكن حفظ المسودة ما لم يكن المستخدم مصادقًا عليه.",
      RestoreDraftInstanceError: "لا يمكن استعادة المسودة لأنه لا يوجد نموذج.",
      Time: "وقت غير صالح",
      CancelButtonAriaLabel: "زر الإلغاء. انقر لإعادة تعيين النموذج",
      PreviousButtonAriaLabel: "زر السابق. انقر للعودة إلى التبويب السابق",
      NextButtonAriaLabel: "زر التالي. انقر للانتقال إلى التبويب التالي",
      SubmitButtonAriaLabel: "زر تقديم النموذج. انقر لتقديم النموذج",
      Copy: "نسخ",
      Move: "نقل",
      "Edit JSON": "تعديل JSON",
      "File": "ملف",
      "reCAPTCHA": "reCAPTCHA",
      "Nested Form": "نموذج متداخل",
      "Custom": "مخصص",
      "browse": "تصفح",
      "File Name": "اسم الملف",
      "Drop files to attach,": "اسحب الملفات لإرفاقها, ",
      "or": "او",
      "Upload": "رفع",

      "BrowseFiles": "تصفح",
      "Browse_Files": "تصفح",
      "browse_files": "تصفح",
      "Browse_files": "تصفح",
      "browse_Files": "تصفح",
      "browseFiles": "تصفح",
      "browsefiles": "تصفح",
      "Browsefiles": "تصفح",
      "Browse Files ": "تصفح",
      "Files": "الملفات",
      "files": "الملفات",
      "Browse": "تصفح",
    },
    fr: {}
  },
};

i18n
  .use(Backend) // Load translations using http (default public/assets/locales)
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Pass the i18n instance to react-i18next.
  .init({
    fallbackLng: "en",
    debug: false,
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // Path to translation files
    },
    interpolation: {
      escapeValue: false, // Not needed for react as it escapes by default
    },
  });

export default i18n;
