import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import BaseUrl from "../services/BaseUrl"; // Adjust the import path as needed

export const handleLogin = createAsyncThunk(
  "user/handleLogin",
  async (credentials, { dispatch }) => {
    const response = await fetch(`${BaseUrl()}/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: credentials }),
    });

    if (response.ok) {
      const token = response.headers.get("x-jwt-token");
      const decoded = jwtDecode(token);
      const result = await response.json();
      result.data.webhookUrl = decoded?.user?.webhookUrl || null;
      dispatch(login({ token, userInfo: result }));
      return { token, userInfo: result };
    } else {
      if (response.status === 401) {
        throw new Error("Email or Password Was Incorrect");
      }
      throw new Error("Login failed");
    }
  },
);

export const handleLogout = createAsyncThunk(
  "user/handleLogout",
  async (_, { dispatch, getState }) => {
    try {
      const token = getState().user.token; // Get the token from the state
      await fetch(`${BaseUrl()}/logout`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-jwt-token": token,
        },
      });
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
      dispatch(logout());
      localStorage.clear();
      // Assuming you have access to navigate here or you can pass navigate as an argument to the thunk
    }
  },
);

export const handleForgetPassword = createAsyncThunk(
  "user/handleForgetPassword",
  async (email, { rejectWithValue }) => {
    const port = window.location.port ? ":" + window.location.port : null;
    const url = port
      ? window.location.protocol +
        "//" +
        window.location.hostname +
        port +
        "/auth/reset_password"
      : window.location.protocol +
        "//" +
        window.location.hostname +
        "/auth/reset_password";
    try {
      const response = await fetch(`${BaseUrl()}/user/forget_password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, url: url }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "Reset failed");
      }

      return response.json();
    } catch (error) {
      return rejectWithValue(error.message || "Reset failed");
    }
  },
);

export const handleResetPassword = createAsyncThunk(
  "user/handleResetPassword",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await fetch(`${BaseUrl()}/user/reset_password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "Reset failed");
      }

      return response.json();
    } catch (error) {
      return rejectWithValue(error.message || "Reset failed");
    }
  },
);

const initialState = {
  token: null,
  user: null,
  authorized: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login(state, action) {
      state.token = action.payload.token;
      state.user = action.payload.userInfo;
      state.authorized = true;
    },
    logout(state) {
      state.token = null;
      state.user = null;
      state.authorized = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleLogin.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.userInfo;
      state.authorized = true;
    });
    builder.addCase(handleLogout.fulfilled, (state) => {
      state.token = null;
      state.user = null;
      state.authorized = false;
    });
    builder.addCase(handleForgetPassword.fulfilled, (state) => {
      state.token = null;
      state.user = null;
      state.authorized = false;
    });
    builder.addCase(handleForgetPassword.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(handleResetPassword.fulfilled, (state) => {
      state.token = null;
      state.user = null;
      state.authorized = false;
    });
    builder.addCase(handleResetPassword.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const { login, refreshTokenSuccess, logout } = userSlice.actions;
export default userSlice.reducer;
