import { useSelector, useDispatch } from "react-redux";
import { CContainer, CHeader, CHeaderNav, CHeaderToggler } from "@coreui/react";
import { UserAccountDropdown } from "./index";
import React, { useEffect, useRef } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AppLanguageDropdown from "./AppLanguageDropdown";
import AppBreadcrumb from "../AppBreadcrumb";

const AppHeader = () => {
  // const { colorMode, setColorMode } = useColorModes('coreui-react-docs-theme')
  const headerRef = useRef();

  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarState.sidebarShow);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      headerRef.current &&
        headerRef.current.classList.toggle(
          "shadow-sm",
          document.documentElement.scrollTop > 0,
        );
    });
  }, []);

  return (
    <CHeader position="sticky" className="mb-4 p-0" ref={headerRef}>
      <CContainer className="border-bottom px-4" fluid>
        <CHeaderToggler
          onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
          style={{ marginInlineStart: "-14px" }}
        >
          <FontAwesomeIcon icon={faBars} size="lg" />
        </CHeaderToggler>
        <CHeaderNav>
          <AppLanguageDropdown />
          <UserAccountDropdown />
        </CHeaderNav>
      </CContainer>
      <CContainer className="px-4" fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
