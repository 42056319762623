import { useEffect } from "react";
import {language} from "../i18n";


const UseLanguageDetectorHook = (languageParam) => {

  useEffect(() => {

    const html = document.getElementsByTagName("html")[0];
    if (languageParam === "ar") {
      html.setAttribute("dir", "rtl");
      language.language = "ar";
    } else {
      html.setAttribute("dir", "ltr");
      language.language = "en";
    }
  }, [languageParam]);


  return language
}

export default UseLanguageDetectorHook
