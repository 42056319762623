import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "./Layout";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = () => {
  const { authorized, token } = useSelector((state) => state.user);

  let isExpired = false;

  if (token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    isExpired = decodedToken.exp < currentTime;
  }

  return authorized ? (
    isExpired ? (
      <Navigate to="/auth/login" />
    ) : (
      <Layout />
    )
  ) : (
    <Navigate to="/auth/login" />
  );
  // return authorized ? <Layout /> : <Navigate to="/auth/login" />
};
export default ProtectedRoute;
