import React, { startTransition } from "react";
import "../css/404.css"
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PageNotFound() {
  const { token, authorized } = useSelector((state) => state.user);
  const handleClick = () => {
    startTransition(() => {
      window.location.href = token && authorized ? "/" : "/auth/login";
    });
  };

  return (
    <div className="not-found">
      <div className="section">
        <h1 className="error">404</h1>
        <div className="page">Oops... The page you are looking for has not been found</div>
        <Link className="back-home" onClick={handleClick} to="#">Back to home</Link>
      </div>
    </div>
  );
}

