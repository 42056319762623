import React from "react";
import {
  CAvatar,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import earth from "../../assets/images/avatars/earth.png";
import us from "../../assets/images/avatars/us.png";
import ar from "../../assets/images/avatars/ar.png";

const AppHeaderDropdown = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    window.location.reload();
  };

  return (
    <CDropdown variant="nav-item" className="align-content-center">
      <CDropdownToggle placement="bottom-end" className="py-0 pe-0 align-content-center" caret={false}>
        <CAvatar src={earth} size="sm" />
        <span className="mx-2 fs-6">
          {localStorage.getItem("lang") && localStorage.getItem("lang") === "ar" ? "AR" : "EN"}
        </span>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-body-secondary fw-semibold mb-2">{t("Language")}</CDropdownHeader>
        <CDropdownItem
          onClick={() => changeLanguage("en")} style={{ cursor: "pointer"}}>
          <CAvatar src={us} size="sm" className="mx-2 lang-flag"/> English
        </CDropdownItem>
        <CDropdownItem
          onClick={() => changeLanguage("ar")} style={{ cursor: "pointer"}}>
          <CAvatar src={ar} size="sm" className="mx-2 lang-flag"/> Arabic
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
